body {
  background-color: #eee;
}

@keyframes wiggle {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg) scale(1);
  }
  25% {
    transform: translateX(-50%) translateY(-50%) rotate(6deg) scale(1.2);
  }
  50% {
    transform: translateX(-50%) translateY(-50%) rotate(-6deg) scale(1);
  }
  75% {
    transform: translateX(-50%) translateY(-50%) rotate(6deg) scale(1.2);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg) scale(1);
  }
}

#logo {
  /* transform: rotateZ(8deg); */
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* border: 1px solid #ddd; */
  /* border-bottom: 0; */
  border-radius: 13px;
  box-shadow: 0px 4px 0px #00000033;
}

/* #logo {
  filter: grayscale(100%);
  opacity: 0.7;
  transition: all 0.5s;
}

#logo:hover {
  filter: none;
  opacity: 1;
  animation: wiggle 1s;
  animation-iteration-count: infinite;
} */

#timeline .card {
  /* opacity: 0.7; */
}
